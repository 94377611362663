import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Accesible() {
  const { t } = useTranslation();
  return (
    <>
 <section className="accesible-sec bg-black pb-12 lg:relative z-10 mt-10 lg:py-[240px]">
  <div className="acess-image lg:absolute right-0 top-0 pl-5 lg:pl-0 z-0">
    <img srcSet="/images/accessible-image.webp" alt="Dispositivos que muestran nuestra web en su pantalla" />
  </div>

  <div className="wrapper mx-auto px-5 lg:px-0 relative z-10">
    <div className="assesible-inner max-w-lg ">
      <h2 className="title-accessible text-3xl lg:text-5xl mb-4 font-black text-white">
        {t("Accesible.titulo")}
      </h2>
      <p className=" subtitle-accessible text-[21px] leading-[1.4] max-w-[480px] text-white opacity-70 min-h-[131px]">
        {t("Accesible.texto")}
      </p>
    </div>
  </div>
</section>





    </>
  );
}
