import React from "react";
import Navbar from "../component/Navbar.js";
import Footer from "../component/Footer.js";
import { useTranslation } from "react-i18next";

export default function ConsultationsPage() {

  const { t } = useTranslation();

  return (
    <>
      <Navbar />

      <section className="faq-sec remove-after relative py-16">
        <div className="wrapper mx-auto">
          <div className="invest-inner text-center pb-16">
            <h2 className="text-center text-[64px] font-black pb-3">
              {t("ConsultationsPage.title")}
            </h2>
            <p className="font-semibold opacity-50 text-[26px]">
              {t("ConsultationsPage.subtitle")}
            </p>
          </div>
          <div className="grid  grid-cols-2 gap-x-8">
            <div className="card-box dark-text">
              <div className="card-inner rounded-2xl bg-white p-8 w-100 border-solid border-0">
                <div className="card-detail ">
                  <h4 className="font-black mt-0">{t("ConsultationsPage.titleCard1")}</h4>
                  <ul>
                    <li>{t("ConsultationsPage.text1Card1")}</li>
                    <li>{t("ConsultationsPage.text1Card2")}</li>
                    {/* <li>Free coaching sessions available on our site</li> */}
                  </ul>
                  <a
                    href="/register"
                    className="rounded-lg bg-white mr-6	blue-btn px-6 font-black h-12	leading-10 border-4 text-white mt-8 text-center max-w-[170px] inline-block"
                  >
                    {t("ConsultationsPage.botonCard1")}
                  </a>
                </div>
              </div>
            </div>
            <div className="card-box">
              <div className="card-inner blue-card rounded-2xl p-8 w-100 border-solid border-0">
                <div className="card-detail">
                  <h4 className="font-black mt-0 text-white">{t("ConsultationsPage.titleCard2")}</h4>
                  <ul>
                  <li>{t("ConsultationsPage.text3Card2")}</li>
                  <li>{t("ConsultationsPage.text2Card2")}</li>
                  </ul>
                  <a
                    href="/contact/premium"
                    className="rounded-lg bg-white mr-6	filled-btn px-6 font-black h-12	leading-10 border-4 border-white text-center max-w-[170px]  mt-8 inline-block"
                  >
                    {t("ConsultationsPage.botonCard2")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
