import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Investment() {
  const { t } = useTranslation();
  return (
    <>
      <section className="investment-sec relative z-10 lg:mt-10 lg:pb-16">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="benefit-inner">
            <div className="flex flex-col lg:flex-row justify-stretch gap-8">
              <div className="lg:w-2/4 card-inner rounded-2xl p-8 lg:p-12">
                <div className="card-icon bg-black">
                  <img src="/images/invest-icon.svg" alt="logo del EURO €" />
                </div>
                <div className="card-detail mt-7">
                  <h4 className="font-black text-2xl lg:text-4xl">{t("Investment.titulo1")}</h4>
                  <p>
                    {t("Investment.texto1")}
                  </p>
                  <a
                    href="/roipage"
                    className="rounded-lg bg-black mr-6	text-white px-6 font-black h-12	leading-10 border-4 border-white  mt-5 inline-block"
                  >
                    {t("Investment.boton1")}
                  </a>
                </div>
              </div>
              
              <div className="lg:w-2/4 card-inner bg-black rounded-2xl p-8 lg:p-12">
                <div className="card-icon">
                  <img src="/images/dr-sleep-icon.webp" alt="logo de robot " />
                </div>
                <div className="card-detail mt-7">
                  <h4 className="font-black text-white text-2xl lg:text-4xl">
                    {t("Investment.titulo2")}
                  </h4>
                  <p className="text-white">
                    {t("Investment.texto2")}
                  </p>
                  <Link
                    to="/sleepbot"
                    className="rounded-lg bg-white mr-6 text-black	 px-6 font-black h-12	leading-10 border-white border-4 mt-5 inline-block"
                  >
                    {t("Investment.boton2")}
                  </Link>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
