import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import isEmail from "is-email";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../auth";
import LoadingButton from "../../component/LoadingButton";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function Login() {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { from } = location.state || { from: { pathname: "/profile" } };
  const [showPassword, setShowPassword] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const returnTo = queryParams.get("returnTo");
  var registrationLink = `/register?returnTo=${from.pathname}`;
  if (returnTo) {
    registrationLink = `/register?returnTo=${returnTo}`;
  }
  const handleButtonClick = () => {
    setShouldDisplayErrors(true);
    handleSubmit(onSubmit)();
  };
  const onSubmit = async (data) => {
    const { email, password } = data;

    setIsSigningIn(true);
    setErrorMessage(undefined);
    try {
      setLoading(true);
      await signIn({ email, password });
      setLoading(false);
      if (returnTo) {
        // Redirect back to the returnTo path
        navigate(returnTo);
      } else {
        // If no returnTo query parameter, redirect to a default page
        navigate(from);
      }
    } catch (error) {
      const res = error.response;
      if (res) {
        const code = res.data?.error?.code;
        if (code === "user-not-found") {
          setError("email", {
            message: t("Login.error1"),
          });
        }
        if (code === "wrong-password") {
          setError("password", {
            message: t("Login.error2"),
          });
          return;
        }
      } else if (error.message === "Correo electrónico no verificado" || error.message === "Email not verified") {
        setError("email", {
          message:
          t("Login.error3"),
        });
      }

      setErrorMessage(t("Login.error4"));
    } finally {
      setIsSigningIn(false);
      setLoading(false);
    }
  };

  const [isSigningIn, setIsSigningIn] = useState(false);
  
  return (
    <>
      <Helmet>
        <title>Iniciar Sesión | SleepZzone </title>
        <meta name="description" content="Accede a tu cuenta de SleepZzone y comienza a mejorar tus hábitos de sueño. Encuentra consejos, seguimiento de tu historial y mucho más." />
        <meta name="keywords" content="Dormir, descansar, bienestar, descanso, sueño reparador, consejos para dormir, rendimiento, aprender a dormir" />
      </Helmet>
      <div className="login-page py-16 relative">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="login-left">
            <a href="/" className="login-logo">
              <img src="images/logo.png" alt="SleepZzone logo" />{" "}
            </a>
            <h3 className="text-white mt-12	  pb-6 head-3 font-black">
              {t("Login.titulo")}
            </h3>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: t("Login.correoRequired"),
                  validate: {
                    validateEmail: (email) =>
                      isEmail(email) || t("Login.correoReal"),
                  },
                }}
                render={({ field }) => (
                  <div className="form-group mb-5">
                    <input
                      {...field}
                      className={
                        "input-login w-full h-12 border-0 rounded-lg text-white px-6"
                      }
                      placeholder={t("Login.correo")}
                      type="email"
                    />
                    {shouldDisplayErrors && errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                )}
              />
              <Controller
                control={control}
                name="password"
                rules={{ required: t("Login.contraseñaRequired") }}
                render={({ field }) => (
                  <div className="form-group mb-5" style={{ position: 'relative' }}>
                    <input
                      {...field}
                      className={
                        "input-login w-full border-0 h-12 rounded-lg text-white px-6"
                      }
                      placeholder={t("Login.contraseña")}
                      type={showPassword ? 'text' : 'password'}
                    />
                    <button
                      type="button" 
                      onClick={() => setShowPassword(!showPassword)}
                      className="password-toggle-button"
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        color: 'white',
                        fontSize: '20px',
                        top: '50%', 
                        transform: 'translateY(-50%)',
                      }}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                    {shouldDisplayErrors && errors.password && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                )}
              />
              <div className="form-group">
                <LoadingButton
                  loading={loading}
                  onClick={handleButtonClick}
                  className="rounded-lg inline-block text-white mr-6 blue-btn py-3.5 px-6 font-black border-2"
                  loadingMessage="Iniciando sesión..."
                >
                  {t("Login.entrar")}
                </LoadingButton>
              </div>
            </form>
            <div className="bottom-links mt-12">
              {t("Login.resetPassword")}
              <Link to="/forgotPassword">{t("Login.aqui")}</Link>
            </div>
            <div className="bottom-links mt-3">
              {t("Login.sinCuenta")}
              <Link to={registrationLink}>{t("Login.registrate")}</Link>
            </div>
            <div className="footer-login mt-20">
              <p>
                © 2023 SleepZzone ·{" "}
                <a href="/politica-de-privacidad" className="underline">
                  {t("Login.privacidad")}
                </a>{" "}
                ·{" "}
                <a rel="/" className="underline">
                  {t("Login.Condiciones")}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="hidden lg:block lg:absolute login-image-right lg:right-0 lg:top-0 h-full">
          <img src="images/login-image-right.jpg" alt="imagen durmiendo para el login" />
        </div>
      </div>
    </>
  );
}
