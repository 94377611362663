// NotFound.js
import React from "react";
import { Link } from "react-router-dom";
import "../NotFound.css"; // Estilos personalizados para NotFound.js

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">Página no encontrada</h1>
      <p className="not-found-text">
        Lo sentimos, la página que buscas no se encuentra disponible.
      </p>
      <Link to="/" className="not-found-link">
        Volver a la página de inicio
      </Link>
    </div>
  );
};

export default NotFound;
