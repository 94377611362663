import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Products() {
  const { t } = useTranslation();
  return (
    <>
      <section className="products-sec py-8 lg:py-16 relative mt-8 lg:mt-16">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="products-inner">
            <h2 className="text-center font-black mb-8 lg:mb-16 text-3xl lg:text-5xl">
              {t("Products.titulo")}
            </h2>
            <div className="product-box relative rounded-2xl p-8 lg:px-16 lg:pt-16">
              <div className="product-listing lg:pb-16">
                <div className="product-list-box active mb-6">
                  <a href="/profile">
                    <h4 className="text-xl text-white font-black">
                      {t("Products.subtitulo1")}
                    </h4>
                  </a>
                  <p className="text-sm lg:text-xl text-white">
                    {t("Products.texto1")}
                  </p>
                </div>
                <div className="product-list-box mb-6">
                  <a href="/consultations">
                    <h4 className="text-xl text-white font-black">
                      {t("Products.subtitulo2")}
                    </h4>
                  </a>
                  <p className="text-sm lg:text-xl text-white">
                    {t("Products.texto2")}
                  </p>
                </div>
                <div className="product-list-box mb-6">
                  <a href="/gallery">
                    <h4 className="text-xl text-white font-black">
                      {t("Products.subtitulo3")}
                    </h4>
                  </a>
                  <p className="text-sm lg:text-xl text-white">
                    {t("Products.texto3")}
                  </p>
                </div>
                <div className="product-list-box">
                  <a href="https://www.sleepzzone.eu" rel="noopener noreferrer">
                    <h4 className="text-xl text-white font-black">
                      {t("Products.subtitulo4")}
                    </h4>
                  </a>
                  <p className="text-sm lg:text-xl text-white">
                    {t("Products.texto4")}
                  </p>
                </div>
              </div>
              <div className="hidden lg:block product-img absolute bottom-0 right-16">
                <img src="/images/movilRecortado3.webp" alt="imagen de la app funcionando en un móvil como ejemplo" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
