import React, {useEffect}from "react";
import { useTranslation } from "react-i18next";
export default function Tabssec() {
  const { t } = useTranslation();

  return (
    <>
      <section id="como-funciona" className="tab-sec-outer bg-neutral-100 relative py-10 lg:py-20">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="tabs">
            <Tabs>
              <Tab text = {t("Tabssec.boton1")} label="soy empresa">
                <div className="tab-content-inner relative z-10">
                  <h2 className="text-center font-black lg:mb-2.5 text-xl lg:text-4xl">
                    {t("Tabssec.titulo1")}
                    <span className="blue-text">{t("Tabssec.titulo12")}</span> {t("Tabssec.titulo13")}
                  </h2>
                  <ul className="flex flex-col lg:flex-row lg:justify-between	lg:flex-wrap">
                    <li>
                      {t("Tabssec.texto11")}
                    </li>
                    <li>
                      {t("Tabssec.texto12")}
                    </li>
                    <li>
                      {t("Tabssec.texto13")}
                    </li>
                    <li>
                      {t("Tabssec.texto14")}
                    </li>
                  </ul>
                </div>
              </Tab>
              <Tab text = {t("Tabssec.boton2")} label="soy empleado">
                <div className="tab-content-inner relative z-10">
                  <h2 className="text-center font-black lg:mb-2.5 text-xl lg:text-4xl">
                    {t("Tabssec.titulo1")}
                    <span className="blue-text">{t("Tabssec.titulo12")}</span> {t("Tabssec.titulo14")}
                  </h2>
                  <ul className="flex justify-between	flex-wrap">
                    <li>
                      {t("Tabssec.texto21")}
                    </li>
                    <li>
                      {t("Tabssec.texto22")}
                    </li>
                    <li>
                      {t("Tabssec.texto23")}
                       <strong>{t("Tabssec.DrNegrita")}</strong>{t("Tabssec.texto231")}
                    </li>
                    <li>
                      {t("Tabssec.texto24")}
                    </li>
                  </ul>
                </div>
              </Tab>
              <Tab text = {t("Tabssec.boton3")} label="soy particular">
                <div className="tab-content-inner relative z-10">
                  <h2 className="text-center font-black lg:mb-2.5 text-xl lg:text-4xl">
                    {t("Tabssec.titulo1")}
                    <span className="blue-text">{t("Tabssec.titulo12")}</span> {t("Tabssec.titulo14")}
                  </h2>
                  <ul className="flex justify-between	flex-wrap">
                    <li>
                      {t("Tabssec.texto31")}
                    </li>
                    <li>
                      {t("Tabssec.texto32")}
                    </li>
                    <li>
                      {t("Tabssec.texto23")}
                      <strong>{t("Tabssec.DrNegrita")}</strong>{t("Tabssec.texto231")}
                    </li>
                    <li>
                      {t("Tabssec.texto34")}
                    </li>
                  </ul>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
}

class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label,
  };

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };
  render() {
    let content;
    let buttons = [];
    let text = [];
    return (
      <div>
        {React.Children.map(this.props.children, (child) => {
          buttons.push(child.props.label);
          text.push(child.props.text);
          if (child.props.label === this.state.activeTab)
            content = child.props.children;
        })}

        <TabButtons
          activeTab={this.state.activeTab}
          buttons={buttons}
          changeTab={this.changeTab}
          text= {text}
        />
        <div className="tab-content rounded-2xl	relative tab-outer p-8 lg:px-16 lg:pt-16 lg:pb-24">
          {content}
        </div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab,text }) => {
  return (
    <div className="tab-buttons mb-8">
      {buttons.map((button) => {
        return (
          <button
            className={button === activeTab ? "active" : ""}
            onClick={() => changeTab(button)}
          >
            {text[buttons.indexOf(button)]}
          </button>
        );
      })}
    </div>
  );
};

const Tab = (props) => {
  return <React.Fragment></React.Fragment>;
};
