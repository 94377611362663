import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer({ isDarkPage }) {
  const { t } = useTranslation();
  const FooterClass = isDarkPage ? "footer-dark" : "footer-normal";
  return (
    <>
      {window.location.pathname !== "/sleepbot" && (
        <a
          href="/sleepbot"
          className="chat-fix-icon fixed right-4 bottom-4 lg:right-16	lg:bottom-16 z-50"
        >
          <img
            className="rounded-lg"
            src="/images/fix-chat-icon.png"
            alt="Ve a sleepbot y hazle preguntas"
          />
        </a>
      )}

      <footer className={`relative ${FooterClass}`}>
        <div className="footer-top relative py-12">
          <div className="wrapper mx-auto px-5 lg:px-0">
            <div className="flex flex-col lg:flex-row">
              <div className="footer-logo w-[250px]">
                <a href="/home">
                  <img
                    src="/images/logo.png"
                    width="167"
                    alt="logo de SleepZzone, te lleva a la página principal"
                  />
                </a>
                {/* <ul className="social-logo mt-4 flex flex-wrap">
                  <li className="mr-4">
                    <a
                      href="https://www.instagram.com/sleepzzone/"
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/images/insta-icon.svg"
                        alt="link que te lleva a nuestra página de instagram"
                      />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="https://www.youtube.com/@SleepZZone"
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/images/yt-icon.svg"
                        alt="link que te lleva a nuestra página de youtube"
                      />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="https://twitter.com/sleepzzone"
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/images/x-icon3.svg"
                        alt="link que te lleva a nuestra página de twitter"
                      />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="https://es.linkedin.com/company/sleepzzone"
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/images/linkedin-icon.svg"
                        alt="link que te lleva a nuestra página de linkedin"
                      />
                    </a>
                  </li>
                  <li className="mr-4">
                    <a
                      href="https://www.tiktok.com/@slepzzone"
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/images/tiktok-icon.svg"
                        alt="link que te lleva a nuestra página de tiktok"
                      />
                    </a>
                  </li>
                </ul> */}
              </div>
              <div className="footer-right-box lg:pl-8 mt-8 lg:mt-0">
                <div className="footer-top-right flex flex-col gap-6 lg:gap-0 lg:flex-row lg:space-x-16">
                  <div className="footer-center">
                    <h4>{t("Footer.Productos.titulo")}</h4>
                    <div className="columns-2">
                      <ul className="">
                        <li>
                          <a href="/consultations">
                            {t("Footer.Productos.texto1")}
                          </a>
                        </li>
                        <li>
                          <a href="/consultationspage">
                            {t("Footer.Productos.texto3")}
                          </a>
                        </li>
                        <li>
                          <a href="/roipage">{t("Footer.Productos.texto6")}</a>
                        </li>
                        <li>
                          <a href="/questionnair">
                            {t("Footer.Productos.texto41")}{" "}
                            <strong>{t("Footer.Productos.texto42")}</strong>
                          </a>
                        </li>
                        <li>
                          <a href="/sleepbot">
                            {t("Footer.Productos.texto71")}{" "}
                            <strong>{t("Footer.Productos.texto72")}</strong>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="footer-last">
                    <h4>{t("Footer.Contenidos.titulo")}</h4>
                    <ul className="">
                      <li>
                        <a href="/gallery">{t("Footer.Contenidos.texto1")}</a>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="footer-last">
                    <h4>{t("Footer.Recursos.titulo")}</h4>
                    <ul className="">
                      <li>
                        <a href="/knowus#news">{t("Footer.Recursos.texto1")}</a>
                      </li>
                      <li>
                        <a href="/knowus#team">{t("Footer.Recursos.texto2")}</a>
                      </li>
                      <li>
                        <a href="/knowus#colaboradores">
                          {t("Footer.Recursos.texto3")}
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="footer-contact mt-8 pt-8">
                  <h5 className="font-black text-white text-base	">
                    {t("Footer.Info.titulo")}
                  </h5>
                  <div className="footer-row flex">
                    <div className="footer-col">
                      <h4 className="text-xl text-white font-black my-3">
                        {t("Footer.Info.titulo1")}
                      </h4>
                      <p className="text-white text-sm opacity-70 mb-2">
                        <strong>{t("Footer.Info.calle11")}</strong>
                        {t("Footer.Info.calle12")}
                      </p>
                      <p className="text-white text-sm opacity-70 mb-2">
                        <strong>{t("Footer.Info.calle21")}</strong>
                        {t("Footer.Info.calle22")}
                      </p>
                      <p className="text-white text-sm opacity-70 mb-2">
                        <strong>{t("Footer.Info.calle31")}</strong>
                        {t("Footer.Info.calle32")}
                      </p>
                      <p className="text-white text-sm opacity-70 mb-2">
                        <strong>{t("Footer.Info.calle41")}</strong>
                        {t("Footer.Info.calle42")}
                      </p>
                      <p className="text-white text-sm opacity-70">
                        <strong>{t("Footer.Info.calle51")}</strong>
                        {t("Footer.Info.calle52")}
                      </p>
                    </div>
                    <div className="footer-col-was pl-12">
                      <div className="contact-info-was">
                        <div className="title-was">
                          <h4 className="text-xl text-white font-black my-3">
                            {t("Footer.Info.titulo2")}
                          </h4>
                        </div>
                        <p className="text-white text-sm opacity-70 mb-2">
                          <a href="mailto:comercial@sleepzzone.com">
                            {t("Footer.Info.email")}
                          </a>
                        </p>
                        <p className="text-white text-sm opacity-70">
                          <a>{t("Footer.Info.telefono1")}</a>
                        </p>
                        <p className="text-white text-sm opacity-70">
                          <a>{t("Footer.Info.telefono2")}</a>
                        </p>
                      </div>
                      <a
                        href="https://wa.me/603367585"
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/images/whatsapp-icon.svg"
                          alt="Contacta con nosotros via whatsapp"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom py-4 lg:py-8">
          <div className="wrapper mx-auto">
            <ul className="footer-bottomlist text-center space-x-8 flex flex-col lg:flex-row justify-center">
              <li>
                <a>© 2023 SleepZzone</a>
              </li>
              <li>
                <Link to="/politica-de-privacidad" alt="Política de privacidad">
                  {t("Footer.Info.privacidad")}
                </Link>
              </li>
              <li>
                <a alt="Cookies">Cookies</a>
              </li>
              <li>
                <Link to="/conditions" alt="Términos y Condiciones">
                  {t("Footer.Info.condiciones")}
                </Link>
              </li>
              <li>
                <a alt="Información de seguridad">
                  {t("Footer.Info.seguridad")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}
