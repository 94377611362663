import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { useAuth } from "../auth.js";
import LoadingButton from "./LoadingButton.js";
import { useNavigate } from "react-router-dom";
import { delay } from "q";
import { useTranslation } from "react-i18next";

const MobileForm = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [loading2, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [description, setDescription] = useState("");
  const { user } = useAuth();
  const handleSubmit = (e) => {
    e.preventDefault();

    sendEmail();

    if (!loading2) {
      navigate("/verification/mobile");
    }
  };

  const sendEmail = () => {
    const serviceId = "sleepzzoneId";
    const templateId = "template_btyw8o5";
    const userId = "m_F9K9HuFgibYIL2W";

    const templateParams = {
      user_name: name,
      user_mail: email,
      user_telephone: telephone,
      user_description: description,
      user_code: user ? user.uid : "Null",
    };
    setLoading(true);
    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });

    delay(5000).then(() => {
      setLoading(false);
    });
  };

  return (
    <div>
      <h2 className="text-xl lg:text-2xl mb-3 lg:mb-4 text-white font-black">
        {t("MobileForm.rellena")}
      </h2>
      <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
        <div className="form-group">
          {/* <label className="text-white" htmlFor="name"></label> */}
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            placeholder={t("MobileForm.nombre")}
            onChange={(e) => setName(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            required
          />
        </div>
        <div className="form-group">
          {/* <label htmlFor="email">:</label> */}
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            placeholder={t("MobileForm.email")}
            onChange={(e) => setEmail(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            required
          />
        </div>
        <div className="form-group">
          {/* <label htmlFor="email">:</label> */}
          <input
            type="text"
            id="telephone"
            name="telephone"
            value={telephone}
            placeholder={t("MobileForm.telefono")}
            onChange={(e) => setTelephone(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            required
          />
        </div>
        <div className="form-group">
          {/* <label htmlFor="email">:</label> */}
          <textarea
            type="text"
            id="description"
            name="description"
            value={description}
            placeholder={t("MobileForm.descripcion")}
            onChange={(e) => setDescription(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            required
          />
        </div>

        <LoadingButton
          loading={loading2}
          className="rounded-lg inline-block text-white blue-btn py-3.5 px-6 w-100 font-black border-2"
          loadingMessage={t("BusinessForm.enviando")}
        >
          {t("MobileForm.boton")}
        </LoadingButton>
      </form>
    </div>
  );
};

export default MobileForm;
