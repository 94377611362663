import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import isEmail from "is-email";
import { useAuth } from "../../auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LoadingButton from "../../component/LoadingButton";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Register() {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
  } = useForm();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/profile" } };
  const queryParams = new URLSearchParams(location.search);
  const returnTo = queryParams.get("returnTo");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  var loginLink = `/login?returnTo=${from.pathname}`;
  if (returnTo) {
    loginLink = `/login?returnTo=${returnTo}`;
  }
  const { signUp, signOut } = useAuth();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setShouldDisplayErrors(true);

    handleSubmit(onSubmit)();
  };

  const onSubmit = async (data) => {
    const { name, email, telephone, disCode, password,passwordRepeat,lastName  } = data;
    if (password !== passwordRepeat) {
      setError("passwordRepeat", {
        type: "manual",
        message: t("Register.passwordMatchError"),
      });
      return;
    }

    setIsSigningUp(true);
    setErrorMessage(undefined);
    try {
      setLoading(true);
      await signUp({ name, email, lastName,password, telephone, disCode });
      setLoading(false);
      await signOut();
      navigate("/verification/register", { state: { from: from }, replace: true });
    } catch (error) {
      const res = error.response;
      if (res) {
        const code = res.data?.error?.code;
        if (code === "email-already-in-use") {
          setError("email", {
            message: t("Register.error1"),
          });
          return;
        }
      }
      setErrorMessage(t("Register.error2"));
    } finally {
      setIsSigningUp(false);
      setLoading(false);
    }
  };
  

  return (
    <>
      <Helmet>
        <title>Regístrate | SleepZzone</title>
        <meta name="description" content="Regístrate en SleepZzone para mejorar tus hábitos de sueño, obtener consejos personalizados y hacer un seguimiento de tu historial de sueño." />
        <meta name="keywords" content="Registro, Dormir, Sueño, Bienestar, Consejos para dormir, Seguimiento de sueño" />
      </Helmet>
      <div className="login-page py-16 relative">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="login-left ">
            <a href="/" className="login-logo">
              <img src="images/logo.png" alt="SleepZzone logo" />{" "}
            </a>
            <h3 className="text-white mt-12	  pb-6 head-3 font-black">
             { t("Register.titulo")}
            </h3>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: t("Register.nombreRequired"),
                }}
                render={({ field }) => (
                  <div className="form-group mb-5">
                    <input
                      {...field}
                      className={`input-login w-full h-12 border-0 rounded-lg text-white px-6`}
                      placeholder={t("Register.nombre")}
                      type="text"
                    />
                    {shouldDisplayErrors && errors.name && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                )}
              />
              <Controller
                control={control}
                name="lastName"
                rules={{
                  required: t("Register.apellidoRequired"),
                }}
                render={({ field }) => (
                  <div className="form-group mb-5">
                    <input
                      {...field}
                      className={`input-login w-full h-12 border-0 rounded-lg text-white px-6`}
                      placeholder={t("Register.apellido")}
                      type="text"
                    />
                    {shouldDisplayErrors && errors.name && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.apellido.message}
                      </p>
                    )}
                  </div>
                )}
              />
              <Controller
                control={control}
                name="email"
                rules={{
                  required: t("Register.correoRequired"),
                  validate: {
                    validateEmail: (email) =>
                      isEmail(email) || t("Register.correoReal"),
                  },
                }}
                render={({ field }) => (
                  <div className="form-group mb-5">
                    <input
                      {...field}
                      className={`input-login w-full h-12 border-0 rounded-lg text-white px-6 `}
                      placeholder= {t("Register.correo")}
                      type="email"
                    />
                    {shouldDisplayErrors && errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                )}
              />

              <Controller
                control={control}
                name="telephone"
                rules={{
                  pattern: {
                    value: /^[0-9+]*$/,
                    message: t("Register.numeroReal"),
                  },
                  required: t("Register.numeroRequired"),
                }}
                render={({ field }) => (
                  <div className="form-group mb-5">
                    <input
                      {...field}
                      className={`input-login w-full h-12 border-0 rounded-lg text-white px-6 `}
                      placeholder={t("Register.numero")}
                      type="text"
                    />
                    {shouldDisplayErrors && errors.telephone && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.telephone.message}
                      </p>
                    )}
                  </div>
                )}
              />

              <Controller
                control={control}
                name="disCode"
                rules={{}}
                render={({ field }) => (
                  <div className="form-group mb-5">
                    <input
                      {...field}
                      className={`input-login w-full h-12 border-0 rounded-lg text-white px-6 `}
                      placeholder= {t("Register.codigo")}
                      type="text"
                    />
                  </div>
                )}
              />

              <Controller
                control={control}
                name="password"
                rules={{
                  required: t("Register.contraseñaRequired"),
                  minLength: {
                    value: 6,
                    message: t("Register.contraseñaValida1"),
                  },
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/,
                    message: t("Register.contraseñaValida2"),
                  },
                }}
                render={({ field }) => (
                  <div className="form-group mb-8" style={{ position: 'relative' }}>
                    <input
                      {...field}
                      className={`input-login w-full h-12 border-0 rounded-lg text-white px-6`}
                      placeholder={t("Register.contraseña")}
                      type={showPassword ? 'text' : 'password'}
                    />
                    <button
                      type="button" 
                      onClick={() => setShowPassword(!showPassword)}
                      className="password-toggle-button"
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        color: 'white',
                        fontSize: '20px',
                        top: '50%', 
                        transform: 'translateY(-50%)',
                      }}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                    {errors.password && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                )}
              />
              <Controller
                control={control}
                name="passwordRepeat"
                rules={{
                  required: t("Register.contraseñaRequired"),
                }}
                render={({ field }) => (
                  <div className="form-group mb-8" style={{ position: 'relative' }}>
                    <input
                      {...field}
                      className={`input-login w-full h-12 border-0 rounded-lg text-white px-6`}
                      placeholder={t("Register.contraseñaRepetir")}
                      type={showPasswordRepeat ? 'text' : 'password'}
                    />
                    <button
                      type="button" 
                      onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                      className="password-toggle-button"
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        color: 'white',
                        fontSize: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      {showPasswordRepeat ? <FaEyeSlash /> : <FaEye />}
                    </button>
                    {errors.passwordRepeat && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.passwordRepeat.message}
                      </p>
                    )}
                  </div>
                )}
              />
              <Controller
                control={control}
                name="privacyAccepted"
                defaultValue={false}
                rules={{ required: t("Register.privacyRequired") }}
                render={({ field }) => (
                  <div className="form-group mb-5">
                    <label className="flex items-center space-x-2 cursor-pointer">
                      <input
                        type="checkbox"
                        {...field}
                        className="h-5 w-5 text-blue-500"
                      />
                      <span className={`text-gray-500`}>
                        <a className="underline ml-1" target="_blank" rel="noopener noreferrer">
                          {t("Register.privacyCheckBox")}
                        </a>
                      </span>
                    </label>
                    {shouldDisplayErrors && !field.value && (
                      <p className="text-red-500 text-sm mt-1">
                        {t("Register.privacyRequired")}
                      </p>
                    )}
                  </div>
                )}
              />
              <LoadingButton
                loading={loading}
                onClick={handleButtonClick}
                className="rounded-lg inline-block text-white mr-6 blue-btn py-3.5 px-6 font-black border-2"
                loadingMessage="Creando cuenta..."
              >
                {t("Register.boton")}
              </LoadingButton>
            </form>
            <div className="bottom-links mt-12">
              {t("Register.conCuenta")} <Link to={loginLink}>{t("Register.inicia")}</Link>
            </div>
            <div className="footer-login mt-24">
              <p>
                © 2023 SleepZzone ·{" "}
                <a href="/politica-de-privacidad" className="underline">
                  {t("Register.privacidad")}
                </a>
                ·{" "}
                <a rel="/" className="underline">
                  {t("Register.Condiciones")}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:block login-image-right lg:absolute lg:right-0	lg:top-0 h-full">
          <img src="images/login-image-right.jpg" alt="imagen durmiendo para el register"/>
        </div>
      </div>
    </>
  );
}
