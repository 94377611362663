import React, { useState, useEffect } from "react";
import { useAuth } from "../../auth.js";
import FooterSmall from "../../component/FooterSmall.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Preloader from "../../component/Preloader.js";
import * as apiService from "../../api-service.js";

export default function Congratulation({
  isPremiumUser,
  handleAdvanceClick,
  advanced,
  handleSF36Click,
  sf36,
}) {
  const { user, loading } = useAuth();
  const [dataState, setDataState] = useState(undefined);
  const [healthy, setHealthy] = useState(false);
  useEffect(() => {
    (async () => {
      if (!loading) {
        if (user) {
          setDataState("loading");
          const userIdToken = await user.getIdToken();
          try {
            const healthy = await apiService.getCurrentPittsburg(
              userIdToken,
              user.uid
            );
            setHealthy(healthy);

            setDataState("success");
          } catch {
            setDataState("error");
          }
        }
      }
    })();
  }, [user, loading]);

  const { t } = useTranslation();

  const title = advanced
    ? t("Congratulation.titleAdvanced")
    : sf36
      ? t("Congratulation.titleSF36")
      : healthy
        ? t("Congratulation.titleHealthy")
        : t("Congratulation.titleDefault");

  const text = advanced
    ? t("Congratulation.textAdvanced")
    : isPremiumUser && sf36
      ? t("Congratulation.textSF36")
      : healthy
        ? t("Congratulation.textHealthy")
        : t("Congratulation.textDefault");

  const child = loading ? (
    <></>
  ) : user ? (
    dataState === "loading" ? (
      <Preloader></Preloader>
    ) : dataState === "error" ? (
      <p>An error occured.</p>
    ) : dataState === "success" ? (
      <div>
        <div className="wrapper mx-auto">
          <div className="question-main py-16">
            <div className="mx-auto max-w-[600px]">
              <div className="congratulation-inner text-center">
                <img
                  className="inline-block"
                  src="/images/congratulation-icon.png"
                  alt="imagen de felicitación"
                />
                <h2 className="text-[32px] font-black mt-4 mb-4">{title}</h2>
                <p className="text-[21px]">{text}</p>

                {advanced && !sf36 ? (
                  <div className="flex items-center justify-center mt-8">
                    <Link to="/profile">
                      <button className="rounded-lg inline-block text-white blue-btn h-[48px] px-6 font-black border-2  mr-5">
                        {t("Congratulation.boton3")}
                      </button>
                    </Link>
                    <Link to="/consultations">
                      <button className="rounded-lg inline-block text-white blue-btn h-[48px] px-6 font-black border-2  mr-5">
                        {t("Congratulation.boton1")}
                      </button>
                    </Link>
                    <button
                      onClick={handleSF36Click}
                      className="h-[48px] px-8 filled-btn backbtn rounded-lg font-black"
                    >
                      {t("Congratulation.botonUltimo")}
                    </button>
                  </div>
                ) : !advanced && !sf36 && !healthy ? (
                  <div className="flex items-center justify-center mt-8">
                    <Link to="/consultations">
                      <button className="rounded-lg inline-block text-white blue-btn h-[48px] px-6 font-black border-2  mr-5">
                        {t("Congratulation.boton1")}
                      </button>
                    </Link>
                    <button
                      onClick={handleAdvanceClick}
                      className="h-[48px] px-8 filled-btn backbtn rounded-lg font-black"
                    >
                      {isPremiumUser
                        ? t("Congratulation.boton2")
                        : t("Congratulation.botonPremium")}
                      { }
                    </button>
                  </div>
                ) : !advanced && !sf36 && healthy ? (
                  <div className="flex items-center justify-center mt-8">
                    <Link to="/Gallery">
                      <button className="rounded-lg inline-block text-white blue-btn h-[48px] px-6 font-black border-2  mr-5">
                        {t("Congratulation.boton4")}
                      </button>
                    </Link>
                  </div>
                ) : (
                  <div className="flex items-center justify-center mt-8">
                    <Link to="/consultations">
                      <button className="rounded-lg inline-block text-white blue-btn h-[48px] px-6 font-black border-2  mr-5">
                        {t("Congratulation.boton1")}
                      </button>
                    </Link>
                    <Link to="/profile">
                      <button className="rounded-lg inline-block text-white blue-btn h-[48px] px-6 font-black border-2  mr-5">
                        {t("Congratulation.boton3")}
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <FooterSmall />
      </div>
    ) : undefined
  ) : (
    <div className="login-page relative">
      <div className="wrapper mx-auto px-5 lg:px-0 text-center py-10 lg:py-20">
        <h4 className="text-4xl mb-4">You're not logged in</h4>
        <a
          href="/"
          className="rounded-lg inline-block text-white text-base bg-zinc-800 hover:bg-zinc-700 py-2 px-6 font-black"
        >
          Back to home
        </a>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Cuestionario Completado | SleepZzone</title>
        <meta
          name="description"
          content="Has completado con éxito el cuestionario de SleepZzone. Obtén consejos y recomendaciones personalizadas para mejorar tu sueño."
        />
        <meta
          name="keywords"
          content="Cuestionario completado, Sueño, Bienestar, Consejos para dormir, Personalizado"
        />
      </Helmet>
      <div>{child}</div>
    </>
  );
}
