import React from "react";
import Modal from "react-modal";
import "../PopupModal.css"; 
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

Modal.setAppElement("#root");

const PopupModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="popup-modal" 
      overlayClassName="popup-overlay" 
      shouldCloseOnOverlayClick={true}
    >
      <div className="popup-content">
        <h2>{t("Banner.titulo-popup")}</h2>
        <p>{t("Banner.subtitulo-popup")}</p>
        <div className="button-container">
          <Link to="/contact/mobile" className="button-left">{t("Banner.boton1-popup")}</Link>
          <button className="button-close" onClick={onClose}>
          {t("Banner.boton2-popup")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PopupModal;
