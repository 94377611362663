import React from "react";
import FooterSmall from "../../component/FooterSmall.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


export default function PreQuestionnair() {
  const { t } = useTranslation();
  
  return (
    <>
    <Helmet>
      <title>Porque hacer un questionario | SleepZzone</title>
      <meta name="description" content="El questionario nos sirve para poder evaluarte y aconsejarte sobre tu salud" />
      <meta name="keywords" content="Cuestionario, Sueño, Bienestar, Consejos para dormir, Personalizado" />
    </Helmet>
      <div>
        <div className="header-question">
          <div className="container mx-auto">
            <div className="logo-top flex items-center py-5">
              <a href="/" className="login-logo mr-8">
                <img
                  className="inline-block"
                  src="/images/logo-blue.svg"
                  alt=""
                />{" "}
              </a>
              <p className="opacity-50 font-semibold	text-xl">
                {t("PreQuestionnair.textNavbar")}
              </p>
            </div>
          </div>
          <div className="border-gradiant"></div>
        </div>
        <div className="wrapper mx-auto">
          <div className="question-main py-16">
            <div className="mx-auto max-w-[600px]">
              <div className="congratulation-inner text-center">
                <img
                  className="inline-block"
                  src="/images/congratulation-icon.png"
                  alt=""
                />
                <h2 className="text-[32px] font-black mt-4 mb-4">{t("PreQuestionnair.titulo")}</h2>
                <p className="text-[21px]">{t("PreQuestionnair.texto")}</p>
                <div className="flex items-center justify-center mt-8">
            
                  <Link to="/questionnair2">
                    <button className="rounded-lg inline-block text-white blue-btn h-[48px] px-6 font-black border-2  mr-5">
                    {t("PreQuestionnair.boton")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterSmall />
      </div>
    </>
  );
}
