import React, { useEffect, useState, useMemo } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import classNames from "classnames";
import FooterSmall from "../../component/FooterSmall.js";
import advanceQuestions from "../../content/advancedForms.json";
import freeQuestions from "../../content/freequestions.json";
import sf36Questions from "../../content/sf36Form.json";
import Congratulation from "./Congratulation.js";
import { timeHour } from "../../constants.js";
import * as apiService from "../../api-service.js";
import { useAuth } from "../../auth.js";
import { useTranslation } from "react-i18next";

const FormStep = ({
  data,
  hidden,
  decreaseCount,
  increseCount,
  onQuestionComplete,
  formIndex,
  formName,
  user,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handleBack = () => {
    if (formIndex > 0 && currentQuestion === 0) return;
    setCurrentQuestion(currentQuestion - 1);

    decreaseCount();
  };

  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1);
    increseCount();
  };

  const formik = useFormik({
    initialValues: {
      questions: data,
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      if (values.questions.length - 1 > currentQuestion) {
        handleNext();
      } else {
        const resultsArray = values.questions.map(
          (question) => question.answer
        );

        const currentQuestionnair = formName;
        // Manda peti del algoritmo
        pushForm(currentQuestionnair, resultsArray);
        setCurrentQuestion(0);
        onQuestionComplete(values.questions);
      }
    },
  });

  const pushForm = async (currentQuestionnair, resultsArray) => {
    const userIdToken = await user.getIdToken();
    try {
      await apiService.pushForm({
        userIdToken,
        userId: user.uid,
        currentQuestionnair,
        resultsArray,
      });
    } catch {
      console.error("error");
    }
  };

  const { values } = formik;
  return (
    <div
      className={classNames("mx-auto step-form-outer max-w-[600px]", {
        hidden: hidden,
      })}
    >
      <form onSubmit={formik.handleSubmit}>
        <FieldArray
          name="questions"
          render={(arrayHelpers) => {
            return (
              <div>
                {values.questions.map((question, qIndex) => {
                  return (
                    <div
                      key={question.position}
                      className={classNames({
                        hidden: currentQuestion !== qIndex,
                      })}
                    >
                      <h2 className="text-[32px] font-black mt-4 mb-8 max-w-[700px] mx-auto">
                        {question.question}
                      </h2>
                      {question.type === "1" &&
                        question.answers.map((answer, index) => {
                          const answerIndex = parseInt(index, 10);
                          return (
                            <label className="block" key={answer}>
                              <input
                                id={`questions.${qIndex}.answer`}
                                type="radio"
                                value={answerIndex}
                                name={`questions.${qIndex}.answer`}
                                onChange={formik.handleChange}
                              />
                              <span className="w-full py-4 px-8 rounded-lg mb-3 relative text-lg flex items-center flex-wrap">
                                {answer}
                              </span>
                            </label>
                          );
                        })}
                      {question.type === "2" && (
                        <input
                          name={`questions.${qIndex}.answer`}
                          type="number"
                          onChange={formik.handleChange}
                          value={question.answer}
                          min={0}
                          max={24}
                          className="bg-gray-100 border border-gray-800 rounded-lg  block w-full py-4 px-8"
                        />
                      )}
                      {question.type === "3" && (
                        <select
                          name={`questions.${qIndex}.answer`}
                          onChange={formik.handleChange}
                          value={question.answer}
                          className="bg-gray-100 border border-gray-800 rounded-lg  block w-full py-4 px-8"
                        >
                          <option>Select</option>
                          {timeHour.map((th) => (
                            <option key={th.name} value={th.value}>
                              {th.name}
                            </option>
                          ))}
                        </select>
                      )}
                      <div className="flex items-center mt-8">
                        {(currentQuestion > 0 || formIndex > 0) && (
                          <button
                            className="h-[64px] px-8 mr-3 backbtn rounded-lg"
                            onClick={handleBack}
                            type="button"
                            disabled={formIndex > 0 && currentQuestion === 0}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="33"
                              height="32"
                              viewBox="0 0 33 32"
                              fill="none"
                            >
                              <path
                                d="M9.37687 16.7564L17.5666 24.9333L16.5 25.9999L6.5 16L16.5 6L17.5666 7.06667L9.37687 15.2436H26.4999V16.7564H9.37687Z"
                                fill="#5723E8"
                              />
                            </svg>
                          </button>
                        )}

                        <button
                          type="submit"
                          disabled={!question.answer}
                          className="rounded-lg inline-block text-white w-full blue-btn h-[64px] px-6 font-black border-2"
                        >
                          Próxima pregunta
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }}
        />
      </form>
    </div>
  );
};

const QuestionNaire = ({
  completed,
  completedAdvanced,
  completedSF36,
  index,
}) => {
  const { t } = useTranslation();
  const { user, loading } = useAuth();
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [currentStep, setCurrentStep] = useState(index);
  const [count, setCount] = useState(1);
  const [complete, setComplete] = useState(completed);
  const [completeAdvanced, setCompleteAdvanced] = useState(completedAdvanced);
  const [completeSF36, setCompleteSF36] = useState(completedSF36);
  const [questions, setQuestions] = useState(freeQuestions);
  const [advanced, setAdvanced] = useState(false);
  const [sf36, setSF36] = useState(false);
  
  useEffect(() => {
    calculateCount(currentStep);
    (async () => {
      if (!loading) {
        if (user) {
          const userIdToken = await user.getIdToken();
          const premium = true;
          try {
            const { userData } = await apiService.getUserData({
              userIdToken,
              userId: user.uid,
            });
            setIsPremiumUser(premium);
          } catch {
            console.error("error");
          }
        }
      }
    })();
  }, [user, loading]);
  const total = useMemo(() => {
    let count = 0;
    questions.forEach((question) => {
      count += question.questions.length;
    });
    return count;
  }, [questions]);

  const calculateCount = (index) => {
    switch (index) {
      case 0:
        setCount(1);
        break;
      case 1:
        setCount(8);
        break;
      case 2:
        setCount(22);
        break;
      case 3:
        setCount(32);
        break;
      case 4:
        setCount(40);
        break;
      case 5:
        setCount(48);
        break;
      case 6:
        setCount(67);
        break;
      case 7:
        setCount(69);
        break;
      case 8:
        setCount(74);
        break;
      case 9:
        setCount(74);
        break;
      default:
        setCount(1);
    }
  };

  const handleAdvanceClick = () => {
    if (isPremiumUser) {
      setQuestions(advanceQuestions);
      setAdvanced(true);
      setComplete(false);
      setCompleteSF36(false);
      setSF36(false);
    } else {
      // Aqui es un navigate a donde llevaría al usuario al hacerse premium
    }
  };

  const handleSF36Click = () => {
    setQuestions(sf36Questions);
    setSF36(true);
    setComplete(false);
    setAdvanced(false);
    setCompleteAdvanced(false);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    if (currentStep === questions.length - 1) {
      setComplete(true);
      setCurrentStep(0);
      setCount(1);

      return;
    }
    increseCount();
    setCurrentStep(currentStep + 1);
  };

  const handleNextAdvanced = () => {
    if (currentStep === questions.length - 1) {
      setCompleteAdvanced(true);
      pushValoration();
      setCurrentStep(0);
      setCount(1);
      return;
    }
    increseCount();
    setCurrentStep(currentStep + 1);
  };

  const handleNextSF36 = () => {
    if (currentStep === questions.length - 1) {
      setCompleteSF36(true);
      setCurrentStep(0);
      setCount(1);
      return;
    }
    increseCount();
    setCurrentStep(currentStep + 1);
  };

  const pushValoration = async () => {
    const userIdToken = await user.getIdToken();
    try {
      await apiService.pushValoration({
        userIdToken,
        userId: user.uid,
      });
    } catch {
      console.error("Error");
    }
  };

  const increseCount = () => {
    setCount(count + 1);
  };

  const decreaseCount = () => {
    setCount(count - 1);
  };

  const formik = useFormik({
    initialValues: {
      questionnair: freeQuestions,
    },
    validateOnChange: false,
    onSubmit: (values) => {
      console.log(questions);
    },
  });

  const formikAdvanced = useFormik({
    initialValues: {
      questionnair: advanceQuestions,
    },
    validateOnChange: false,
    onSubmit: (values) => {
      console.log(advanceQuestions);
    },
  });

  const formikSF36 = useFormik({
    initialValues: {
      questionnair: sf36Questions,
    },
    validateOnChange: false,
    onSubmit: (values) => {
      console.log(sf36Questions);
    },
  });

  var onQuestionComplete = (index, questions) => {
    formik.setFieldValue(`questionnair.${index}.questions`, questions);
    handleNext();
  };
  var { values } = formik;
  if (sf36) {
    onQuestionComplete = (index, questions) => {
      formik.setFieldValue(`questionnair.${index}.questions`, questions);

      handleNextSF36();
    };
    var { values } = formikSF36;
  }
  if (advanced) {
    onQuestionComplete = (index, questions) => {
      formikAdvanced.setFieldValue(
        `questionnair.${index}.questions`,
        questions
      );

      handleNextAdvanced();
    };
    var { values } = formikAdvanced;
  }

  return (
    <div>
      <div className="header-question">
        <div className="container mx-auto">
          <div className="logo-top flex items-center py-5">
            <a href="/" className="login-logo mr-8">
              <img
                className="inline-block"
                src="/images/logo-blue.svg"
                alt="SleepZzone logo"
              />{" "}
            </a>
            <p className="opacity-50 font-semibold	text-xl">
              {t("PreQuestionnair.textNavbar")}
            </p>
          </div>
        </div>
        <div className="border-gradiant"></div>
      </div>
      {complete ? (
        <Congratulation
          handleAdvanceClick={handleAdvanceClick}
          handleSF36Click={handleSF36Click}
          isPremiumUser={isPremiumUser}
          advanced={false}
          sf36={false}
        />
      ) : completeSF36 ? (
        <Congratulation
          handleAdvanceClick={handleAdvanceClick}
          handleSF36Click={handleSF36Click}
          isPremiumUser={isPremiumUser}
          advanced={false}
          sf36={true}
        />
      ) : completeAdvanced ? (
        <Congratulation
          handleAdvanceClick={handleAdvanceClick}
          handleSF36Click={handleSF36Click}
          isPremiumUser={isPremiumUser}
          advanced={true}
          sf36={false}
        />
      ) : (
        <div className="wrapper mx-auto">
          <div className="question-main py-16">
            <div className="text-center">
              <p className="opacity-50">
                Pregunta {count} de {total}
              </p>
              <FormikProvider
                value={advanced ? formikAdvanced : sf36 ? formikSF36 : formik}
              >
                <FieldArray
                  name="questionnair"
                  render={(arrayHelpers) => {
                    return values.questionnair.map((form, index) => {
                      return (
                        <FormStep
                          currentStep={currentStep}
                          key={index}
                          data={form.questions}
                          hidden={index !== currentStep}
                          increseCount={increseCount}
                          decreaseCount={decreaseCount}
                          onQuestionComplete={(questions) =>
                            onQuestionComplete(index, questions)
                          }
                          formIndex={index}
                          formName={form.name}
                          user={user}
                        />
                      );
                    });
                  }}
                />
              </FormikProvider>
            </div>
          </div>
        </div>
      )}

      <FooterSmall />
    </div>
  );
};

export default QuestionNaire;
