import React, { useEffect } from "react";
import FooterSmall from "../component/FooterSmall.js";
import Navbar from "../component/Navbar.js";
import "./PrivacyPage.css";
import { Helmet } from "react-helmet";

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
  <>
    <Helmet>
      <title>Política de Privacidad - SleepZzone</title>
      <meta name="description" content="Lee nuestra Política de Privacidad para entender cómo recopilamos, utilizamos y protegemos tus datos personales en SleepZzone." />
      <meta name="keywords" content="Política de privacidad, Datos personales, Protección de datos, Privacidad en línea" />
    </Helmet>
    <div className="privacy-page">
      <Navbar isInnerPage={true} />
      <div className="privacy-container privacy-content">
        <section className="privacy-section">
          <h1 className="privacy-title">Políticas de privacidad en SleepZzone</h1>
          <p className="privacy-text">
          El usuario queda informado sobre el tratamiento de los datos suministrados durante la navegación en la página Web y los que se generen como consecuencia de la utilización de esta, incluidas, en su caso, las comunicaciones o las transferencias internacionales de los datos que pudieran realizarse, con las finalidades indicadas en el apartado  En caso de que los datos facilitados se refieran a terceras personas físicas distintas del usuario, éste garantiza haber recabado y contar con el consentimiento previo de los mismos para la comunicación de sus datos y haberles informado, con carácter previo a facilitarlos, de las finalidades del tratamiento, comunicaciones y demás términos previstos en el apartado Información sobre Protección de Datos Garantizas que eres mayor de 18 años y que todos los datos facilitados te corresponden. Si eres menor de 16 años antes de proceder a incluir tus datos personales en los formularios habilitados debes contar con la autorización previa de tus padres, tutores o representantes legales, quienes, según la legislación vigente, serán considerados responsables de todos los actos que realices. El usuario garantiza la exactitud y veracidad de los datos facilitados, adquiriendo el compromiso de comunicar a SLEEPZZONE LAND SL cualquier cambio en los mismos. La utilización de esta web está sujeta a las Condiciones de Uso (enlace) y a la Política de Privacidad y Tratamiento de Datos Personales, que se detallan a continuación, así como a la Política de Cookies (enlace). Le rogamos que lea atentamente.
          </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">Información de protección de datos</h1>
          <h2 className="privacy-subtitle">¿Quién es el responsable del tratamiento de tus datos personales?</h2>
          <ul className="custom-list">
            <li>Denominación social: SLEEPZZONE LAND SL</li>
            <li>Nombre comercial: SLEEPZZONE</li>
            <li>Número de Identificación Fiscal: B88586219</li>
            <li>Domicilio Social: CALLE ORFILA, 7 2 º. 28010, MADRID</li>
            <li>Email de contacto: info@sleepzzone.com</li>
            <li>Teléfono de contacto: 865 660 465 / 603 367 585</li>
            <li>Inscrita en el registro mercantil de Madrid, al tomo 40245, folio 37, hoja número M-715027, inscripción 1ª.</li>
           </ul>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">¿Qué datos recabamos?</h1>
          <p className="privacy-text ">
            Los datos que SLEEPZZONE LAND SL conserva son los siguientes:
            <ul className="custom-list custom-margin">
                <li>Datos identificativos: nombre, apellido,  correo electrónico, email, teléfono.</li>
                <li>Datos de salud, como por ejemplo la información de tu perfil de salud.</li>
            </ul>
          </p>
          <p className="privacy-text">
            En caso de no suministrar al menos tales datos, considerados como necesarios, SLEEPZZONE LAND SL no podrá gestionar tu solicitud, consulta o contratación.
           </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">¿Con qué finalidad tratamos tus datos personales?</h1>
          <p className="privacy-text">
          A continuación, se detallan las finalidades de los tratamientos:
          
            <p>- En relación con los formularios facilitados:</p>
                <ul className="custom-list custom-margin">
                    <li>Tramitar el registro en la aplicación.</li>
                    <li>Gestionar la solicitud y contratación de nuestros servicios y productos.</li>
                    <li>Realizar estudios y cálculos estadísticos, encuestas, análisis de tendencias del mercado y control de calidad.</li>
                    <li>Elaborar los perfiles necesarios para la personalización y el acompañamiento diseñado específicamente para ti dentro de SLEEPZZONE en base a tus interacciones con nosotros.</li>
                    <li>Tramitar tu solicitud de alta como profesional del cuadro médico.</li>
                    <li>Atender y gestionar tus sugerencias, reclamaciones y otras consultas que realices a través del formulario correspondiente o de los números de teléfono del centro de soporte facilitados a tal efecto.</li>
                </ul>
            <p>- En relación con la gestión de tu área privada:</p>
                <ul className="custom-list custom-margin">
                    <li>Facilitarte el acceso para la gestión y consulta de tus datos, así como los resultados de los servicios y productos contratados.</li>
                </ul>
            <p>- Respecto al envío de comunicaciones comerciales:</p>
                <ul className="custom-list custom-margin">
                    <li>Trataremos tus datos para enviarte información y publicidad, incluso por vía electrónica, sobre ofertas, productos, recomendaciones, servicios, promociones, obsequios y campañas de fidelización de SLEEPZZONE, en base a la elaboración de perfiles mediante estudios de marketing y estadísticos a partir de tus compras previas y la información que hayas proporcionado en tu perfil de salud para que dichas comunicaciones se ajusten todo lo posible a tus necesidades y preferencias.</li>
                    <li>Enviarte comunicaciones comerciales de SLEEPZZONE y de empresas colaboradoras con las que tengamos acuerdos relevantes para ti.</li>
                </ul>
          </p>
          <p className="privacy-text">
            En caso de no suministrar al menos tales datos, considerados como necesarios, SLEEPZZONE LAND SL no podrá gestionar tu solicitud, consulta o contratación.
           </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">¿Por cuánto tiempo conservaremos tus datos personales?</h1>
          <p className="privacy-text">
          Trataremos tus datos hasta que decidas darte de baja de la aplicación. Una vez ocurrido esto, se conservarán bloqueados durante el plazo de prescripción de las acciones derivadas de las relaciones contractuales.
          </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">¿A qué destinatarios se comunicarán tus datos?</h1>
          <p className="privacy-text">
          SLEEPZZONE LAND SL, podrá comunicar tus datos, exclusivamente para las finalidades indicadas en el apartado ¿Con qué finalidad tratamos tus datos personales? a los siguientes destinatarios:
          
            <p><strong>Posibles destinatarios:</strong></p>
                <ul className="custom-list custom-margin">
                    <li>Otras entidades pertenecientes a SLEEPZZONE, así como terceros con los que suscribamos acuerdos de colaboración necesarios para la prestación del servicio solicitado.</li>
                    <li>Colaboradores, incluyéndose aquí centros médicos y profesionales.</li>
                    <li>Empresas encargadas de cubrir una función específica en el desarrollo de la actividad principal de SLEEPZZONE (por ejemplo, un centro de recepción de llamadas) bajo nuestra completa supervisión.</li>
                </ul>
            <p><strong>Tus derechos:</strong></p>
                <ul className="custom-list custom-margin">
                    <li><strong>Acceso: </strong>Conocer qué datos personales tuyos tenemos.</li>
                    <li><strong>Rectificación: </strong>Solicitar la rectificación de sus datos inexactos.</li>
                    <li><strong>Supresión: </strong>Solicitar la supresión de sus datos cuando, entre otros motivos, ya no sean necesarios para los fines para los que fueron recogidos.</li>
                    <li><strong>Limitación del tratamiento: </strong>Solicitar que suspendamos el tratamiento de sus datos, si, por ejemplo, los datos son inexactos o el tratamiento es ilícito, pudiendo no obstante ser tratados para el ejercicio o la defensa de posibles reclamaciones, la protección de los derechos de otra persona o por razones de interés público.</li>
                    <li><strong>Oposición: </strong>Oponerse al tratamiento de sus datos, salvo cuando sean necesarios para, entre otros motivos, el desarrollo de la relación contractual, en caso de que la hubiera, o para el ejercicio o la defensa de posibles reclamaciones.</li>
                    <li><strong>Portabilidad: </strong>Recibir sus datos personales en un formato estructurado, de uso común y legible, o solicitar su transmisión a otro responsable cuando sea técnicamente posible.</li>
                </ul>
          </p>
          <p className="privacy-text">
            <strong>Te recordamos que también puede, en cualquier momento, revocar el consentimiento prestado para el tratamiento de sus datos, en su caso.</strong>
           </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">¿Cómo ejercitar tus derechos?</h1>
          <p className="privacy-text">
          Para ejercer tus derechos, debes dirigirte al responsable, solicitando el correspondiente formulario para el ejercicio del derecho elegido.  Opcionalmente, puede acudir a la Autoridad de Control competente para obtener información adicional acerca de sus derechos.  Los datos de contacto para el ejercicio de sus derechos son el teléfono 603367585 y el correo electrónico:info@sleepzzone.com. Recuerda acompañar una copia de un documento que nos permita identificarte. Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento, por lo que es recomendable visitarla regularmente. Los cambios y aclaraciones entrarán en vigor inmediatamente después de su publicación en el sitio web.  Si hacemos cambios materiales a esta política, notificaremos aquí que ha sido actualizada.
          </p>
        </section>

        <section className="privacy-section">
          <h1 className="privacy-title">Google API Disclosure</h1>
          <p className="privacy-text custom-margin">
  Sleepzzone uses and transfers to any other app information received from Google APIs will adhere to 
  <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noopener noreferrer">
    <strong> Google API Services User Data Policy</strong>
  </a>,
  including the Limited Use requirements.
</p>

        </section>

        {/* Add more sections for rules, terms, etc. */}
      </div>
      <FooterSmall />
    </div>
    </>
  );
};

export default PrivacyPage;
