import React, {useState} from "react";
import Navbar from "../../component/Navbar.js";
import Footer from "../../component/Footer.js";
import ReactAudioPlayer from "react-audio-player";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
export default function Gallery() {
  const { t } = useTranslation();
  const isInnerPage = true;
  const isDarkPage = true;
  const [currentSound, setCurrentSound] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);

  const getVideoId = (url) => {
    const videoIdMatch = url.match(/(\?|&)v=([^&#]+)/);
    return videoIdMatch ? videoIdMatch[2] : "";
  };

  const sounds = [
    {
      soundUrl: "sounds/1.mp3",
      img: "images/soundsImg/1.jpg",
      duration: "21:04 Mins",
      //lock: "../images/lock-icon.svg",
      playlistIicon: "",
      playlisttitle: "",
      title: "Meditation Sounds",
      subtitle: "RelaxingTime"
    },
    {
      soundUrl: "sounds/2.mp3",
      img: "images/soundsImg/2.jpg",
      duration: "15:59 Mins",
      //lock: "",
      playlistIicon: "👶👧",
      playlisttitle: "Niños",
      title: "The Old Water Mill Meditation",
      subtitle: "NaturesEye"
    },
    {
      soundUrl: "sounds/13.mp3",
      img: "images/soundsImg/13.jpg",
      duration: "22:10 Mins",
      //lock: "../images/lock-icon.svg",
      playlistIicon: "🥰",
      playlisttitle: "Sleep medication",
      title: "Valley of Hope Meditation",
      subtitle: "Alan Frijins",
    },
    {
      soundUrl: "sounds/14.mp3",
      img: "images/soundsImg/14.jpg",
      duration: "25:00 Mins",
      //lock: "../images/lock-icon.svg",
      playlistIicon: "🥰",
      playlisttitle: "Sleep medication",
      title: "Meditation at the river yoga zen",
      subtitle: "Alan Frijins",
    },
    // {
    //   soundUrl: "sounds/3.mp3",
    //   img: "images/soundsImg/3.jpg",
    //   duration: "50 Mins",
    //   lock: "../images/lock-icon.svg",
    //   playlistIicon: "👫",
    //   playlisttitle: "Mayores",
    //   title: "Name of the song",
    // },
    // {
    //   soundUrl: "sounds/4.mp3",
    //   img: "images/soundsImg/4.jpg",
    //   duration: "50 Mins",
    //   lock: "../images/lock-icon.svg",
    //   playlistIicon: "👏",
    //   playlisttitle: "Nuevo",
    //   title: "Name of the song",
    // },
    {
      soundUrl: "sounds/5.mp3",
      img: "images/soundsImg/5.jpg",
      duration: "22:04 Mins",
      //lock: "",
      playlistIicon: "🥰",
      playlisttitle: "Sleep medication",
      title: "Summer Rain Meditation",
      subtitle: "NaturesEye"
    },
    {
      soundUrl: "sounds/6.mp3",
      img: "images/soundsImg/6.jpg",
      duration: "20:11 Mins",
      //lock: "",
      playlistIicon: "",
      playlisttitle: "",
      title: "3 Prayers",
      subtitle: "NaturesEye",
    },
    {
      soundUrl: "sounds/7.mp3",
      img: "images/soundsImg/7.jpg",
      duration: "2:18 Mins",
      //lock: "../images/lock-icon.svg",
      playlistIicon: "",
      playlisttitle: "",
      title: "Forest Lullaby",
      subtitle: "Lesfm",
    },
    {
      soundUrl: "sounds/8.mp3",
      img: "images/soundsImg/8.jpg",
      duration: "4:00 Mins",
      //lock: "../images/lock-icon.svg",
      playlistIicon: "🥰",
      playlisttitle: "Sleep medication",
      title: "Bathroom - Chill Background Music",
      subtitle: "chillmore",
    },
    {
      soundUrl: "sounds/9.mp3",
      img: "images/soundsImg/9.jpg",
      duration: "2:40 Mins",
      lock: "",
      playlistIicon: "",
      playlisttitle: "",
      title: "Just Relax",
      subtitle: "Lesfm",
    },
    {
      soundUrl: "sounds/10.mp3",
      img: "images/soundsImg/10.jpg",
      duration: "30:00 Mins",
      lock: "",
      playlistIicon: "",
      playlisttitle: "",
      title: "Frequency of Sleep Meditation",
      subtitle: "NaturesEye",
    },
    {
      soundUrl: "sounds/11.mp3",
      img: "images/soundsImg/11.jpg",
      duration: "15:14 Mins",
      //lock: "../images/lock-icon.svg",
      playlistIicon: "",
      playlisttitle: "",
      title: "Simply Meditation Series - Delta Waves",
      subtitle: "NaturesEye",
    },
    {
      soundUrl: "sounds/15.mp3",
      img: "images/soundsImg/12.jpg",
      duration: "3:12:15 Mins",
      //lock: "../images/lock-icon.svg",
      playlistIicon: "👶👧",
      playlisttitle: "Niños",
      title: "Ruido Blanco",
      subtitle: "Sueña Feliz",
    },
    // {
    //   soundUrl: "sounds/13.mp3",
    //   img: "images/soundsImg/13.jpg",
    //   duration: "22:10 Mins",
    //   lock: "../images/lock-icon.svg",
    //   playlistIicon: "🥰",
    //   playlisttitle: "Sleep medication",
    //   title: "Valley of Hope Meditation",
    //   subtitle: "Alan Frijins",
    // },
    // {
    //   soundUrl: "sounds/14.mp3",
    //   img: "images/soundsImg/14.jpg",
    //   duration: "25:00 Mins",
    //   lock: "../images/lock-icon.svg",
    //   playlistIicon: "🥰",
    //   playlisttitle: "Sleep medication",
    //   title: "Meditation at the river yoga zen",
    //   subtitle: "Alan Frijins",
    // },
    // {
    //   soundUrl: "sounds/15.mp3",
    //   img: "images/soundsImg/15.jpg",
    //   duration: "50 Mins",
    //   lock: "../images/lock-icon.svg",
    //   playlistIicon: "🥰",
    //   playlisttitle: "Sleep medication",
    //   title: "Name of the song",
    // },
    // {
    //   soundUrl: "sounds/16.mp3",
    //   img: "images/soundsImg/16.jpg",
    //   duration: "50 Mins",
    //   lock: "../images/lock-icon.svg",
    //   playlistIicon: "🥰",
    //   playlisttitle: "Sleep medication",
    //   title: "Name of the song",
    // },
  ];
  const videos = [
    {
      videoUrl: "https://www.youtube.com/watch?v=4mWVlylBKkk&t=321s&pp=ygUacHJvZmUgY2xhdWRpbyBuaWV0byBzdWXDsW8%3D",
      img: "images/videosImg/1.jpg",
      duration: "8:49 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Descubre la IMPORTANCIA de respetar la FASE REM de tu SUEÑO",
      subtitle: "Claudio Nieto",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=TWDUpJvZIiI&pp=ygUacHJvZmUgY2xhdWRpbyBuaWV0byBzdWXDsW8%3D",
      img: "images/videosImg/2.jpg",
      duration: "16:18 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Las CLAVES del cerebro: SUEÑO y ESTRÉS, por Marcos Vázuez",
      subtitle: "Claudio Nieto",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=oWuWr7gd1A8&pp=ygUacHJvZmUgY2xhdWRpbyBuaWV0byBzdWXDsW8%3D",
      img: "images/videosImg/3.jpg",
      duration: "20:50 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Calambres, lesiones y sueño, por Kilian Jornet",
      subtitle: "Claudio Nieto",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=f94GMCnR_qs&pp=ygUacHJvZmUgY2xhdWRpbyBuaWV0byBzdWXDsW8%3D",
      img: "images/videosImg/4.jpg",
      duration: "5:53 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Mejora tu CALIDAD DE SUEÑO aumentando el TRIPTÓFANO que cruza la BARRERA HEMATOENCEFÁLICA",
      subtitle: "Claudio Nieto"
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=P_e5oGXo6YU&t=1s&pp=ygUacHJvZmUgY2xhdWRpbyBuaWV0byBzdWXDsW8%3D",
      img: "images/videosImg/5.jpg",
      duration: "10:21 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Así Influye TU SUEÑO en tu SISTEMA INMUNE ¡¡¡ 4 CONSEJOS CLAVE !!!",
      subtitle: "Claudio Nieto"
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=a7R39UtIznU",
      img: "images/videosImg/6.jpg",
      duration: "4:40 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Las rutinas del sueño y el pre sueño",
      subtitle: "Dr. Gonzalo Pin Arboledas - Educar es Todo"
    },

    {
      videoUrl: "https://www.youtube.com/watch?v=gedoSfZvBgE&t=8s",
      img: "images/videosImg/7.jpg",
      duration: "5:45 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Los beneficios de dormir bien",
      subtitle: "Shai Marcu - TED-Ed",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=eqgQqAC1sQw",
      img: "images/videosImg/8.jpg",
      duration: "18:16 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Cuidar nuestro sueño es velar por el equilibrio de nuestra salud",
      subtitle: "Gonzalo Pin - TED x UPValència",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=zmlH1Nw03Gg&pp=ygUacHJvZmUgY2xhdWRpbyBuaWV0byBzdWXDsW8%3D",
      img: "images/videosImg/13.jpg",
      duration: "7:12 Mins",
      //lock: "../images/lock-icon.svg",
      title: "¿Qué relación tiene el RENDIMIENTO COGNITIVO y el SUEÑO? Con el Dr Antelm Pujol",
      subtitle: "Claudio Nieto",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=xnOyX2jRHKk",
      img: "images/videosImg/10.jpg",
      duration: "54:44 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Sueño y descanso en niños y adolescentes",
      subtitle: "Dr. Gonzalo Pin Arboledas ",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=5PpGeK3yrUg",
      img: "images/videosImg/11.jpg",
      duration: "59:44 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Protocolos prácticos para que NADA TE QUITE EL SUEÑO, con M.ÁNGELES BONMATÍ",
      subtitle: "Claudio Nieto",
    },
    {
      videoUrl: "https://www.youtube.com/watch?v=HNiXxPBpexs",
      img: "images/videosImg/12.jpg",
      duration: "1:07:18 Mins",
      //lock: "../images/lock-icon.svg",
      title: "Rendimiento FÍSICO, COGNITIVO y HORMONAL: SINCRONIZA tus RITMOS CIRCADIANOS, con JUAN ANTONIO MADRID",
      subtitle: "Claudio Nieto",
    },
  ];

  const audioWrapperStyles = {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
  };

  const audioStyles = {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
    marginTop: '8px', // Adjust this value to control the space between the image and the audio player
  };
 

  return (
    <>
      <Helmet>
        <title>Vídeos y Sonidos del Sueño: Accede a nuestros vídeos para aprender del sueño y dormir mejor | SleepZzone</title>
        <meta name="description" content="Explora nuestra colección de vídeos y sonidos relajantes para mejorar la calidad de tu sueño. Encuentra la tranquilidad que necesitas para dormir mejor." />
        <meta name="keywords" content="Videos de sueño, Sonidos relajantes, Dormir mejor, Bienestar, Sueño reparador, Relajación, Meditación para dormir" />
      </Helmet>
      <Navbar isInnerPage={isInnerPage} />
      <section className="main-gallery-page pb-8 lg:pb-32">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="gallery-inner pt-32 lg:pt-16">
            <h3 className="text-white text-center text-2xl lg:text-4xl leading-snug font-black">
              {t("Gallery.titulo")}
            </h3>
            <p className="opacity-70 text-lg lg:text-xl text-white pb-6 leading-relaxed text-center">
              {t("Gallery.subtitulo")}
            </p>
            <Tabs>
              <Tab text = {t("Gallery.boton1")} label="Videos">
                <div className="tab-content-inner relative z-10">
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                    {videos.map((video, index) => {
                      return (
                        <div className="gallery-tiles">
                          <div className="gallery-relative relative">
                            <div className="gallery-thumb">
                            {currentVideo === index ? (
                              <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${getVideoId(video.videoUrl)}`}
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                              ></iframe>
                            ) : (
                              <img
                                className="w-full rounded-lg"
                                src={video.img}
                                alt="Imagen de nuestra galería para el vídeo seleccionado"
                                onClick={() => setCurrentVideo(index)}
                              />
                            )}
                            </div>
                            <span className="media-duration trans-bg inline-block top-3.5 left-3.5 absolute px-2 py-1 font-semibold  text-xs text-white  z-10">
                              {video.duration}
                            </span>
                            {video.playlistIicon || video.playlisttitle ? (
                              <div className="title-trans">
                                {video.playlistIicon}{" "}
                                <span>{video.playlisttitle}</span>
                              </div>
                            ) : null}

                            {video.lock ? (
                              <span className="media-lock">
                                <img src={video.lock} alt="Lock icon" />
                              </span>
                            ) : null}
                          </div>
                          <div className="gallery-title pt-3">
                            <h4 className="text-white font-black">
                              {video.title}
                            </h4>
                            <p style={{ color: 'grey', fontSize: '14px', opacity: 0.7 }}>{video.subtitle}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tab>
              <Tab text= {t("Gallery.boton2")} label="Sonidos">
                <div className="tab-content-inner relative z-10">
                  <div className="grid grid-cols-4 gap-8">
                    {sounds.map((sound, index) => {
                      return (
                        <div className="gallery-tiles">
                          <div className="gallery-relative relative">
                            <div style = {audioWrapperStyles} className="gallery-thumb">
                              <img
                                className="w-full rounded-lg"
                                src={sound.img}
                                alt="Imagen de nuestra galería para el sonido"
                                onClick={() => {
                                  // Create a reference to the ReactAudioPlayer component
                                  const audioPlayer = document.getElementById(`audio-${index}`);
                                  if (audioPlayer) {
                                    if (audioPlayer.paused) {
                                      audioPlayer.play();
                                    } else {
                                      audioPlayer.pause();
                                    }
                                  }
                                }}
                              />
                            </div>
                            <span className="media-duration trans-bg inline-block top-3.5 left-3.5 absolute px-2 py-1 font-semibold  text-xs text-white  z-10">
                              {sound.duration}
                            </span>
                            {sound.playlistIicon || sound.playlisttitle ? (
                              <div className="title-trans">
                                {sound.playlistIicon}{" "}
                                <span>{sound.playlisttitle}</span>
                              </div>
                            ) : null}

                            {sound.lock ? (
                              <span className="media-lock">
                                <img src={sound.lock} alt="Lock icon" />
                              </span>
                            ) : null}
                          </div>

                          <div className="gallery-title pt-3">
                          <ReactAudioPlayer
                                id={`audio-${index}`}
                                src={sound.soundUrl}
                                controls={true} // Turn off default controls
                                autoPlay={false}
                                style={audioStyles}
                              />
                            <h4 className="text-white font-black">
                              {sound.title}
                            </h4>
                            <p style={{ color: 'grey', fontSize: '14px', opacity: 0.7 }}>{sound.subtitle}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
      <Footer isDarkPage={isDarkPage} />
    </>
  );
}

class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label,
  };
  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };
  render() {
    let content;
    let buttons = [];
    let text = [];
    return (
      <div>
        {React.Children.map(this.props.children, (child) => {
          buttons.push(child.props.label);
          text.push(child.props.text)
          if (child.props.label === this.state.activeTab)
            content = child.props.children;
        })}

        <TabButtons
          activeTab={this.state.activeTab}
          buttons={buttons}
          changeTab={this.changeTab}
          text = {text}
        />
        <div className="gallery-tab">{content}</div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab,text }) => {
  return (
    <div className="gallery-tab-buttons flex justify-center text-center mb-16">
      {buttons.map((button) => {
        return (
          <button
            className={button === activeTab ? "active" : ""}
            onClick={() => changeTab(button)}
          >
            {text[buttons.indexOf(button)]}
          </button>
        );
      })}
    </div>
  );
};

const Tab = (props) => {
  return <React.Fragment></React.Fragment>;
};
