import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Retrieve the selected language from localStorage or use a fallback language
const selectedLanguage = localStorage.getItem('selectedLanguage') || 'es';

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es', // Specify the fallback language here (if needed)
    lng: selectedLanguage, // Set the initial language based on localStorage
    // ... other i18next configuration options ...
  });

export default i18next;
