import React from "react";
import { useTranslation } from "react-i18next";
export default function Benefits() {
  const { t } = useTranslation();
  return (
    <section className="benefits-sec py-8 lg:py-16">
      <div className="wrapper mx-auto px-5 lg:px-0">
        <div className="benefit-inner">
          <h2 className="text-center font-black mb-8 lg:mb-16 text-3xl lg:text-5xl">
            {t("Benefits.titulo1")}<br />
            <span className="blue-text">{t("Benefits.titulo2")}</span>{" "}
          </h2>
          <div className="flex flex-col lg:flex-row justify-stretch gap-8">
            <div className="card-inner blue-card lg:w-2/4 rounded-2xl p-12 border-solid border-4">
              <div className="card-img">
                <img width="123" src="/images/company-icon.png" alt="imagen de empresa" />
              </div>
              <div className="card-detail">
                <h4 className="font-black text-white text-2xl lg:text-4xl">{t("Benefits.subtitulo1")}</h4>
                <ul>
                  <li>{t("Benefits.texto11")}</li>
                  <li>{t("Benefits.texto12")}</li>
                  <li>{t("Benefits.texto13")}</li>
                  <li>{t("Benefits.texto14")}</li>
                  <li>{t("Benefits.texto15")}</li>
                  <li>{t("Benefits.texto16")}</li>
                </ul>
              </div>
            </div>
            
            <div className="card-inner lg:w-2/4 blue-card rounded-2xl bg-white p-12 w-100 border-solid border-4">
              <div className="card-img">
                <img width="123" src="/images/employee-icon.png" alt="imagen de empleado" />
              </div>
              <div className="card-detail">
                <h4 className="font-black text-white text-2xl lg:text-4xl">{t("Benefits.subtitulo2")}</h4>
                <ul>
                  <li>{t("Benefits.texto21")}</li>
                  <li>{t("Benefits.texto22")}</li>
                  <li>{t("Benefits.texto23")}</li>
                  <li>{t("Benefits.texto24")}</li>
                  <li>{t("Benefits.texto25")}</li>
                  <li>{t("Benefits.texto26")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
