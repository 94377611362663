import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../auth";
import { resendAuth0VerificationEmail } from "../api-service";

const VerificationPage = () => {
  const [emailSended, setEmailSended] = useState(false);
  const { loginWithRedirect } = useAuth0();
  const { userAuth0Context } = useAuth();

  useEffect(() => {}, [userAuth0Context?.email_verified]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { type } = useParams();
  const verificationText = (
    <>
      {t("Verification.verificationText1")}
      <br />
      {t("Verification.verificationText2")}
    </>
  );
  const title =
    type === "register"
      ? t("Verification.titleRegister")
      : type === "consultas"
      ? t("Verification.titleConsultas")
      : t("Verification.titleContact");
  const buttonText =
    type === "register" ? t("Verification.iniciar") : t("Verification.volver");
  const text =
    type === "register"
      ? verificationText
      : type === "consultas"
      ? t("Verification.textConsultas")
      : t("Verification.textContact");
  const handleNavigate = () => {
    if (type === "register") loginWithRedirect();
    else navigate("/home");
  };

  useEffect(() => {
    emailSended && resendEmailVerificationHandler();
  }, [emailSended]);

  const resendEmailVerificationHandler = async () => {
    try {
      userAuth0Context?.sub &&
        (await resendAuth0VerificationEmail(userAuth0Context?.sub));
      console.log("Email sended successfully time out 10 seconds");
    } catch (error) {
      console.error("Email could not be resend for some reason");
    }
  };

  useEffect(() => {
    if (emailSended) {
      setTimeout(() => {
        navigate("/home", { state: "verification" });
      }, 5000);
    }
  }, [emailSended]);

  return (
    <div className="verification-page">
      <div className="conainer-verification text-center">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="verification-image">
            <img
              src="/images/verification.png"
              alt="Verificación de email"
              style={{ display: "block", margin: "0 auto" }}
            />
          </div>
          <div className="verification-header mt-4">
            <h1 style={{ fontSize: "2em", fontWeight: "bold" }}>{title}</h1>
          </div>
          <div className="verification-text mt-4">
            <p className="text-center">{text}</p>
          </div>
          <div className="mt-4">
            {type === "register" && (
              <div className="rounded-lg inline-block text-white blue-btn py-3.5 px-6 font-black border-2 mb-3 mr-4">
                {/* TODO: CREATE ENDPOINT TO RESEND EMAIL FROM BACKEND */}
                {/* <Link to= "/resend">{t("Verification.reenviar")} </Link> */}
                {/* SHOULD BE REMOVE  */}
                <button
                  type="button"
                  disabled={emailSended}
                  onClick={() => {
                    setEmailSended(true);
                  }}
                >
                  Reenviar el email
                </button>
              </div>
            )}
            <div
              className="rounded-lg inline-block text-purple bg-white py-3.5 px-6 font-black border-2"
              onClick={handleNavigate}
              style={{ cursor: "pointer" }}
            >
              {buttonText}
            </div>
            {emailSended && <p>Email de verificación enviado</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationPage;
