import React from "react";
import Navbar from "../component/Navbar.js";
import Footer from "../component/Footer.js";
import CTA from "../component/CtaSec.js";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
export default function ROIpage() {
  const { t } = useTranslation();
  const isHomePage = true;

  return (
    <>
      <Helmet>
        <title>Dashboard ROI: Potencia tus Beneficios usando el sueño - SleepZzone</title>
        <meta name="description" content="Descubre cómo SleepZzone puede ayudarte a mejorar tu retorno de inversión y maximizar tus beneficios, mediante la mejora del sueño y salud de los empleados." />
        <meta name="keywords" content="ROI, Retorno de inversión, Beneficios, Inversión, Maximizar ganancias" />
      </Helmet>
      <Navbar isHomePage={isHomePage} />
      <section className="banner-sec relative">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="center-text-main max-w-[700px] mx-auto text-center pt-32 pb-12 lg:py-48">
            <h2 className="text-center relative mb-3 z-10 font-black text-white text-3xl lg:text-5xl">
              {t("Roipage.titulo1")}
            </h2>
            <p className="opacity-70 text-lg lg:text-xl text-white leading-relaxed">
              {t("Roipage.subtitulo1")}
            </p>
          </div>
        </div>
      </section>

      <div className="border-gradiant"></div>

      <section className="employee-sec py-12 lg:py-24">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="employe-inner">
            <h2 className="text-center relative z-10 font-black text-white text-2xl lg:text-5xl leading-tight max-w-[700px] mx-auto">
              {t("Roipage.titulo2")}
            </h2>

            <h3 className="text-center relative z-10 font-normal text-white mt-2 lg:mt-16 text-lg lg:text-3xl">
              {t("Roipage.subtitulo2")}
            </h3>

            <div className="cost-sec mt-8 flex flex-col lg:flex-row lg:flex-wrap items-center">
              <div className="cost-box rounded-2xl	 p-8 text-center">
                <h2 className="relative font-black text-white text-3xl lg:text-5xl mb-1	">
                  1500€
                </h2>
                <p className="text-white text-[21]">
                  {t("Roipage.box1")}
                </p>
              </div>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                >
                  <g opacity="0.5">
                    <path
                      d="M22.5 26H10V23H22.5V10.5H25.5V23H38V26H25.5V38.5H22.5V26Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </span>
              <div className="cost-box rounded-2xl	 p-8 text-center">
                <h2 className="relative font-black text-white text-3xl lg:text-5xl mb-1	">
                  1800€
                </h2>
                <p className="text-white text-[21]">
                  {t("Roipage.box2")}
                </p>
              </div>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                >
                  <g opacity="0.5">
                    <path
                      d="M22.5 26H10V23H22.5V10.5H25.5V23H38V26H25.5V38.5H22.5V26Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </span>
              <div className="cost-box rounded-2xl p-8 text-center">
                <h2 className="relative font-black text-white text-3xl lg:text-5xl mb-1	">
                  2700€
                </h2>
                <p className="text-white text-[21]">
                  {t("Roipage.box3")}
                </p>
              </div>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                >
                  <g opacity="0.5">
                    <path d="M8 34V29H40V34H8ZM8 20V15H40V20H8Z" fill="white" />
                  </g>
                </svg>
              </span>
              <div className="cost-box rounded-2xl total-cs-box p-8 text-center">
                <h2 className="relative font-black text-white text-3xl lg:text-5xl mb-1	">
                  6000€
                </h2>
                <p className="text-white text-[21]">
                  {t("Roipage.box4")}
                </p>
              </div>
            </div>
            <p className="text-center text-white mt-8 lg:mt-16 text-sm">
              <span className="opacity-30 font-black">{t("Roipage.Referencias")}:</span>{" "}
              <a className="opacity-30 underline" href="https://www.rand.org/" alt="RAND study" target="_blank" rel="noopener noreferrer">
                RAND study,
              </a>{" "}
              <a className="opacity-30 underline" href="https://www.sleepfoundation.org/" alt="Sleep Association" target="_blank" rel="noopener noreferrer">
                Sleep Association,
              </a>{" "}
              <a className="opacity-30 underline" href="https://news.harvard.edu/gazette/" alt="Harvard Gazette" target="_blank" rel="noopener noreferrer">
                Harvard Gazette,
              </a>{" "}
              <a className="opacity-30 underline"  alt="5 proven strategies to decrease employee absenteeism" target="_blank" rel="noopener noreferrer">
                5 proven strategies to decrease employee absenteeism,
              </a>{" "}
              <a className="opacity-30 underline"  alt="The cost of poor sleep: Workplace Productivity & Associated costs" target="_blank" rel="noopener noreferrer">
                The cost of poor sleep: Workplace Productivity & Associated costs
              </a>
            </p>


          </div>
        </div>
      </section>
      <section className="start-sec py-12 lg:py-24">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <h2 className="font-black text-3xl lg:text-5xl mb-3 text-center">
            {t("Roipage.titulo3")}
          </h2>
          <p className="font-normal opacity-50 text-base lg:text-xl mb-6 lg:mb-12 text-center">
            {t("Roipage.subtitulo3")}
          </p>

          <div className="grid gap-8 lg:grid-cols-3">
            <div className="how-box w-ful p-8 rounded-2xl bg-white text-center">
              <div className="icon-sol">
                <img
                  className="inline-block rounded-full"
                  src="/images/solution-icon-1.png"
                  alt="icono de monigote de médico"
                />
              </div>
              <h3 className="font-black mt-8">{t("Roipage.medicos")}</h3>
              <p className="opacity-70 text-[21px]">
                {t("Roipage.textoMedicos")}
              </p>
              <Link to="/consultations">
                <button className="bg-blue-500 text-white py-3 px-6 rounded-lg mt-4">
                  {t("Roipage.boton")}
                </button>
              </Link>
            </div>
            <div className="how-box w-ful p-8 rounded-2xl bg-white text-center">
              <div className="icon-sol">
                <img
                  className="inline-block rounded-full"
                  src="/images/solution-icon-2.svg"
                  alt="icono de psicólogo"
                />
              </div>
              <h3 className="font-black mt-8">{t("Roipage.psicologos")}</h3>
              <p className="opacity-70 text-[21px]">
                {t("Roipage.textoPsicologos")}
              </p>
              <Link to="/consultations">
                <button className="bg-blue-500 text-white py-3 px-6 rounded-lg mt-4">
                  {t("Roipage.boton")}
                </button>
              </Link>
            </div>
            <div className="how-box w-ful p-8 rounded-2xl bg-white text-center">
              <div className="icon-sol">
                <img
                  className="inline-block rounded-full"
                  src="/images/solution-icon-3.svg"
                  alt="icono de entrenadores del sueño"
                />
              </div>
              <h3 className="font-black mt-8">{t("Roipage.entrenadores")}</h3>
              <p className="opacity-70 text-[21px]">
                {t("Roipage.textoEntrenadores")}
              </p>
              <Link to="/consultations">
                <button className="bg-blue-500 text-white py-3 px-6 rounded-lg mt-4">
                  {t("Roipage.boton")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="tab-sec-outer bg-neutral-100 relative py-10 lg:py-20 px-5 lg:px-0">
        <div className="wrapper mx-auto">
          <div className="assesment-header text-center">
            <h2 className="font-black text-3xl lg:text-5xl mb-3 text-center">
              {t("Roipage.titulo4")}
            </h2>
            <p className="font-normal opacity-50 text-xl lg:text-2xl mb-8 lg:mb-16 text-center">
              {t("Roipage.subtitulo4")} 
            </p>
          </div>

          <div className="grid gap-8 lg:grid-cols-2">
            <div className="asses-box">
              <div className="asses-img">
                <img
                  className="rounded-2xl"
                  src="/images/assesment-1.jpg"
                  alt="imagen cuadro de mando de indicadores ROI"
                />
              </div>
              <p className="opacity-40 font-semibold mt-3">{t("Roipage.textoFoto1")}</p>
            </div>
            <div className="asses-box">
              <div className="asses-img">
                <img
                  className="rounded-2xl"
                  src="/images/assesment-2.jpg"
                  alt="imagen de informes de evaluación y seguimiento de indicadores"
                />
              </div>
              <p className="opacity-40 font-semibold mt-3">
                {t("Roipage.textoFoto2")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <CTA />
      <Footer />
    </>
  );
}
