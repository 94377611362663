// Menu.js
import React from "react";
import { Link } from "react-router-dom";
import { FaWpforms, FaRegImages } from "react-icons/fa";

import { FaUserDoctor } from "react-icons/fa6";
import { BiSolidBot } from "react-icons/bi";
import { MdEditDocument } from "react-icons/md";
import { useTranslation } from "react-i18next";

function SideMenu() {
  const { t } = useTranslation();
  return (
    <div className="menu">
      <Link to="/questionnair" className="menu-link transition duration-500">
        <FaWpforms class="w-1/4 mb-4" />
        <span>{t("SideMenu.cuestionario")}</span>
      </Link>
      <Link to="/consultations" className="menu-link transition duration-500">
        <FaUserDoctor class="w-1/4 mb-4" />
        <span>{t("SideMenu.consultas")}</span>
      </Link>
      <a href="/documents/sleepDiary.xlsx" className="menu-link transition duration-500" target="_blank" download="diario-del-sueno.xlsx">
        <MdEditDocument class="w-1/4 mb-4" />
        <span>{t("SideMenu.diario")}</span>
      </a>
      <Link to="/gallery" className="menu-link transition duration-500">
        <FaRegImages class="w-1/4 mb-4" />
        <span>{t("SideMenu.galeria")}</span>
      </Link>
      <Link to="/sleepbot" className="menu-link transition duration-500">
        <BiSolidBot class="w-1/4 mb-4" />
        <span>{t("SideMenu.sleepbot")}</span>
      </Link>
     
    </div>
  );
}

export default SideMenu;

