import React from 'react';
import Navbar from '../../component/Navbar';
import Footer from '../../component/Footer';

const BlogPage = () => {
  const title = "¡Descubriendo los Secretos del Sueño! Entrevista con la Dra. Milagros Merino";
  const text = `
    Es un placer compartir en RADIO CADENA SER PRISA Radio una entrevista con la Dra. Mila Merino Andreu, Presidenta de la Sociedad Española del Sueño (SES), y la periodista María Manjavacas.

    Durante la conversación, se destacó una tendencia impactante: los españoles han estado durmiendo 2 horas menos en las últimas dos décadas, lo que resulta en un déficit de sueño que cuesta al país más del 2% de su PIB, según estudios citados por #SleepZZone.

    Tanto la Dra. Merino como Manjavacas enfatizaron la urgente necesidad de elevar y formalizar la profesión de la Medicina del Sueño en ámbitos políticos, clínicos, académicos, sociales e institucionales, siguiendo prácticas similares en otros países.

    Les invitamos a escuchar esta conversación reveladora con la Dra. Milagros Merino en Radio CADENA SER 
  `;

  // Define an array of keywords you want to be bold
  const boldKeywords = ['déficit de sueño', 'PIB', 'Medicina del Sueño', 'reveladora', 'entrevista', 'urgente necesidad'];

  // Function to replace keywords with bold formatting
  const formatText = (text, keywords) => {
    const pattern = new RegExp(`\\b(${keywords.join('|')})\\b`, 'g');
    return text.split('\n\n').map((paragraph, index) => (
      <p key={index}>
        {paragraph.split(pattern).map((part, index) => (
          pattern.test(part) ? <strong key={index}>{part}</strong> : part
        ))}
      </p>
    ));
  };

  return (
    <>
    <Navbar whiteHeader ={true} isHomePage={false}/>
    <div className="page-container">
        
        <br></br>
        <div className="blog-container pt-32" >
        <div className="blog-header">
            <h1 className="blog-title">{title}</h1>
        </div>
        <div className="blog-content">
            <img
            src="images/CADENA_SER_CON_LA_PRESIDENTA_SES.jpeg"
            alt="Publicación de LinkedIn"
            className="blog-image"
            />
            <div className="blog-text">
            {formatText(text, boldKeywords)}
            </div>
            <div className="blog-bold-centered" style={{ textAlign: 'center' }}>
            <strong style={{ fontSize: '90%', color: '#3b2f75'}}>
                ¡¡SABER DORMIR ES SABER VIVIR!!
            </strong>
            </div>
        </div>
        <div className="blog-footer">
            {/* Add buttons for sharing on social media, comments section, etc. */}
        </div>
        </div>
        <br></br> {/* Add space here */}
        <Footer/>
    </div>
    </>
  );
};

export default BlogPage;
